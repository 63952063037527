import { useJsonld } from '#jsonld';

export function useStructuredJob(page) {
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: page?.title,
        description: page?.data?.description,
        datePosted: page?.meta?.first_published_at,
        hiringOrganization: {
            '@type': 'Organization',
            name: 'LUMA Delikatessen',
            url: 'https://luma-delikatessen.ch',
            logo: 'https://luma-delikatessen.ch/static/logo-black.svg',
        },
        jobLocation: {
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'Gewerbestrasse 6',
                addressLocality: 'Neuhausen am Rheinfall',
                addressRegion: 'Schaffhausen',
                postalCode: '8212',
                addressCountry: 'CH',
            },
        },
    });
}
