<template>
    <div class="spacer-navigation-overlap row">
        <div class="col-12 spacer-60-50">
            <div class="slider">
                <Slider
                    :wrapAround="true"
                    :showCurrent="true"
                    :slides="images"
                    :itemsToShow="1"
                    :breakpoints="{}"
                >
                    <template #default="image">
                        <NuxtImg
                            sizes="xs:630 sm:767px md:1023px lg:1710px"
                            densities="1x 2x"
                            format="webp"
                            :src="image.src"
                            :alt="image.alt"
                            loading="lazy"
                            class="slider__image"
                            :placeholder="[300, 200, 60, 2]"
                        />
                    </template>
                </Slider>
                <hr />
            </div>
        </div>
    </div>
    <div class="row justify-content-center spacer-100-80">
        <div class="col-12 col-800-10 col-1200-8">
            <div
                class="equal-base equal-base--tighter-bold-uppercase posting__info"
            >
                {{ info }}
            </div>
            <h1
                class="scale-6 scale-6--bold-uppercase posting__title"
                :id="slugifyString(page.title)"
            >
                {{ page.title }}
            </h1>
            <div class="scale-2 scale-2--lead">
                {{ description }}
            </div>
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-140-100">
        <CmsBody :page="page" />
    </div>
    <CmsContentInfoBox v-bind="infoBox" class="spacer-140-100" />
    <CmsContentContactPerson v-bind="contactPerson" class="spacer-footer" />
</template>

<script setup>
import { computed } from 'vue';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useStructuredJob } from '~/composables/structuredData/job';
import { slugifyString } from '~/utils/slugify';

const images = computed(() => page?.value?.data?.images);
const info = computed(() => page?.value?.data?.info);
const description = computed(() => page?.value?.data?.description);
const infoBox = computed(() => page?.value?.data?.info_box);
const contactPerson = computed(() => page?.value?.data?.contact_person);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useHrefLang(page?.value?.meta?.hreflang);
useStructuredJob(page?.value);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slider {
    @include media-breakpoint-down(800) {
        :deep(.slider) {
            margin-left: $padding-container-negative;
            margin-right: $padding-container-negative;
        }

        :deep(.slider__navigation) {
            margin-left: (-$padding-container-negative);
            margin-right: (-$padding-container-negative);
        }
    }

    &__image {
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        width: 100%;
        object-fit: cover;
    }

    :deep(.slider__slide) {
        padding: 0;
    }

    hr {
        color: $color-dark-01;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.posting {
    &__info {
        color: $color-disabled;
        margin-bottom: 10px;
    }

    &__title {
        margin-bottom: 15px;
    }
}
</style>
